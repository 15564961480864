import { InitOptions } from "react-ga4/types/ga4";

import { URLS } from "@/constants";

export type PartnerTracker = {
  pathname: string;
  initialize: InitOptions;
  meta: {
    init: string;
    track: "PageView";
    metaImageURL: string;
  };
  gtmId: string;
};

export const PartnerTrackers: PartnerTracker[] = [
  {
    pathname: URLS.aosmith.landing("aosmith"),
    initialize: {
      trackingId: "G-L7DGEMGDCC",
    },
    meta: {
      init: "1416194759167111",
      track: "PageView",
      metaImageURL:
        "https://www.facebook.com/tr?id=1416194759167111&ev=PageView&noscript=1",
    },
    gtmId: "GTM-KHQBVNG",
  },
  {
    pathname: URLS.aosmith.landing("state_water_heaters"),
    initialize: {
      trackingId: "G-H3JVRER35G",
    },
    meta: {
      init: "1043306946998433",
      track: "PageView",
      metaImageURL:
        "https://www.facebook.com/tr?id=1043306946998433&ev=PageView&noscript=1",
    },
    gtmId: "GTM-KHQBVNG",
  },
  {
    pathname: URLS.aosmith.landing("lowes"),
    initialize: {
      trackingId: "G-0E8XSJ9PTJ",
    },
    meta: {
      init: "550437953975803",
      track: "PageView",
      metaImageURL:
        "https://www.facebook.com/tr?id=550437953975803&ev=PageView&noscript=1",
    },
    gtmId: "GTM-TXKW5L3",
  },
];
