import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppEnum, CSS_VARIABLES } from "@/constants";
import { isNil, isProd } from "@/utils";

import { SVCE_GOOGLE_TAG_MANAGER_ID } from "./constants";
import { useBrandingConfig, useDetermineApp } from "./hooks";
import { IBrandingContext } from "./types";
import { initializeGoogleTagManager } from "./utils";

const BrandingContext = createContext<IBrandingContext | undefined>(undefined);

export const BrandingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [dynamicStyles, setDynamicStyles] = useState<Record<string, string>>(
    {}
  );
  const { branding: appBranding } = useDetermineApp();
  const { brandConfig, logo } = useBrandingConfig(appBranding);

  const brandingStyles = useMemo(
    () => CSS_VARIABLES[appBranding] ?? CSS_VARIABLES[AppEnum.RockRabbit],
    [appBranding]
  );

  const is = (app: AppEnum) => {
    return appBranding === app;
  };

  useEffect(() => {
    if (is(AppEnum.SVCE) && isProd()) {
      initializeGoogleTagManager(SVCE_GOOGLE_TAG_MANAGER_ID);
    }
  }, [appBranding]);

  useEffect(() => {
    const newDynamicStyles = Object.keys(brandingStyles).reduce<
      Record<string, string>
    >((acc, key) => ({ ...acc, [key]: brandingStyles[key] }), {});

    Object.entries(newDynamicStyles).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
    setDynamicStyles(newDynamicStyles);
  }, [brandingStyles]);

  return (
    <BrandingContext.Provider
      value={{ appBranding, is, dynamicStyles, logo, brandConfig }}>
      {children}
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => {
  const context = useContext(BrandingContext);

  if (isNil(context)) {
    throw new Error(
      "useBrandingContext must be used within a BrandingProvider"
    );
  }

  return {
    ...context,
  };
};
