export const LOCAL_STORAGE_KEYS = {
  LOCALE: "locale",
  TABLE__PROJECT_INDEX_PROJECT_LIST: "table__project_index_project_list",
  TABLE__PROJECT_INDEX_APPLICATION_LIST:
    "table__project_index_application_list",
  TABLE__APP_ADMIN_APPLICATION_LIST: "table__app_admin_application_list",
} as const;

export const SESSION_STORAGE_KEYS = {
  TRACKING_DISCLAIMER: "aos_disclaimer_dismissed",
  EMAIL_NOTIF_DISMISSED: "email_notif_dismissed",
} as const;
