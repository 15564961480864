import { usePathname } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const pathname = usePathname();

  useEffect(() => {
    const dismissAll = () => {
      toast.dismiss();
    };

    return () => {
      dismissAll();
    };
  }, [pathname]);

  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};
