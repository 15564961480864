import { AppEnum } from "@/constants";
import { BrandConfiguration } from "@/contexts/branding/types";

export const SVCE_HOSTNAMES = [
  "svce.gorockrabbit.com",
  "svce.gorockrabbit.net",
  "assisted.svcleanenergy.org",
  "svce-demo.gorockrabbit.com",
];
export const CPAU_HOSTNAMES = [
  "cpau.gorockrabbit.com",
  "cpau-demo.gorockrabbit.com",
  "cpau.gorockrabbit.net",
];
export const SVCE_GOOGLE_TAG_MANAGER_ID = "GTM-KPTGWJVJ";

export const APP_LOGO: { [key in AppEnum]?: { url: string; nameKey: string } } =
  {
    [AppEnum.SVCE]: {
      url: "/images/header-logo-svce.png",
      nameKey: "header.logo_alt.svce",
    },
    [AppEnum.CPAU]: {
      url: "/images/header-logo-cpau.png",
      nameKey: "header.logo_alt.cpau",
    },
  };

export const DEFAULT_BRAND_CONFIGURATION: BrandConfiguration = {
  brandName: null,
  shouldRedirectContractorsToRockRabbit: false,
};
