import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  ContractorRegistryEntryRequest,
  CreateUserRequest,
  SupportedUSStatesEnum,
  Token,
  UpdateUserRequest,
} from "@/api/BackendAPI";
import { client } from "@/api/client";
import { QUERY_KEYS } from "@/constants";
import useAuthStore from "@/store/auth";

export function useRequestSMSMutation() {
  return useMutation({
    mutationFn: (query: { phone_number: string }) => {
      return client.api.otpSendPhoneNumberOtp(query);
    },
  });
}

export function useSendEmailOTP() {
  return useMutation({
    mutationFn: (query: { email: string }) => client.api.otpSendEmailOtp(query),
  });
}

export function setAccessToken(token: Token, queryClient: QueryClient) {
  const { setState } = useAuthStore.getState();

  setState({ token: token.access_token, userId: token.user_id });

  /**
   * @description
   *
   * Why? token could not be ready in `src/api/client.ts` because of the async nature of the store
   *
   * Invalidate user info query to refetch the user data
   * BUT, we need to wait for the token to be set in the store
   * setTimeout callback function will be called after the token is set
   * (Different render cycle)
   * Better way could be not to use store for token. We do not need to save this token in the store.
   * TODO: I will remove auth store using zustand, store token inside the localStorage and use react-query with staleTime infinite
   */
  setTimeout(() => {
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.user._base() });
  }, 0);
}

export function useVerifyOTPMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: client.api.otpVerifyOtp,
    onSuccess: (token) => setAccessToken(token, queryClient),
  });
}

export function useCompleteSignup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateUserRequest) =>
      client.api.usersCompleteSignup(data),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.user.info(), data);
    },
  });
}

export function useUserInfoQuery(options?: {
  enabled?: boolean;
  refetchInterval?: number | false;
}) {
  const { refetchInterval = false, enabled = true } = options || {};

  return useQuery({
    queryKey: QUERY_KEYS.user.info(),
    queryFn: ({ signal }) => client.api.usersReadUserMe({ signal }),
    refetchInterval,
    staleTime: Infinity,
    enabled,
  });
}

export function useUserOrgsQuery(options?: { enabled?: boolean }) {
  const { enabled = true } = options ?? {};

  return useQuery({
    queryKey: QUERY_KEYS.user.orgs(),
    queryFn: ({ signal }) => client.api.usersGetUserOrganizations({ signal }),
    enabled,
  });
}

export function useUpdateUserTypeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: client.api.usersUpdateUserType,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.user.info(), data);
    },
  });
}

export const useContractorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      licenseNumber,
      state,
    }: {
      licenseNumber: string;
      state: SupportedUSStatesEnum;
    }) =>
      client.api.utilsGetContractor({
        license_number: licenseNumber,
        state: state,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.user.info(),
      });
    },
  });
};

export const useContractorJoinMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ token }: { token: string }) =>
      client.api.contractorsJoinContractor(token),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.user.info(),
      });
    },
  });
};

export const useContractorClaimMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ContractorRegistryEntryRequest) =>
      client.api.contractorsClaimContractor(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.user.info(),
      });
    },
  });
};

export function useUpdateUserMe() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateUserRequest) => client.api.usersUpdateUserMe(data),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.user.info(), data);
    },
  });
}

export const useGetTermsOfConditionsHtml = () => {
  return useQuery({
    queryKey: QUERY_KEYS.user.termsOfConditionsHtml(),
    queryFn: () => client.api.usersGetTos(),
  });
};

export const useAcceptTermsOfConditionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => client.api.usersAcceptTos(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.user.info(),
      });
    },
  });
};
