import { ReactNode, useEffect, useState } from "react";

import { LocaleEnum } from "@/api/BackendAPI";
import { Spinner } from "@/components/common/spinner";
import { useSupportedLocalesQuery } from "@/hooks/queries/useUtilQueries";
import { configureI18n } from "@/utils/i18n";

interface LocalesProviderProps {
  children: ReactNode;
}

const VALID_LANGUAGE_CODES = [LocaleEnum.En, LocaleEnum.Es];

export const LocalesProvider = ({ children }: LocalesProviderProps) => {
  const { data: supportedLocales } = useSupportedLocalesQuery();

  const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (supportedLocales) {
      const lang = supportedLocales.user_locale;
      configureI18n(lang, () => {
        // language codes -- https://www.w3schools.com/tags/ref_language_codes.asp
        if (VALID_LANGUAGE_CODES.includes(lang))
          document.documentElement.setAttribute(
            "lang",
            VALID_LANGUAGE_CODES.includes(lang) ? lang : LocaleEnum.En
          );
        setTranslationsLoaded(true);
      });
    } else {
      setTranslationsLoaded(false);
    }
  }, [setTranslationsLoaded, supportedLocales]);

  if (!supportedLocales || !translationsLoaded) {
    return (
      <main>
        <div className="flex h-dvh w-full items-center justify-center">
          <Spinner />
        </div>
      </main>
    );
  }

  return children;
};
