import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { AppApiApiV1SchemasUserUserStatus } from "@/api/BackendAPI";
import { URLS } from "@/constants";
import { Environments } from "@/types";

export const cx = (...val: ClassValue[]) => twMerge(clsx(...val));

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

export const kabobToSnake = (str: string): string => str.replaceAll("-", "_");

export const getCurrentISOFormat = () => {
  const now = new Date();
  const isoString = now.toISOString();
  const microseconds = now.getMilliseconds() * 1000;
  const microsecondString = String(microseconds).padStart(6, "0");
  const formatted = isoString.replace("Z", "") + microsecondString + "Z";

  return formatted;
};

/**
 * Check if two arrays are equal without considering the order of elements.
 */
export const areUnorderedArraysEqual = <T extends string | number>(
  arr1: T[],
  arr2: T[]
): boolean => {
  // Step 1: Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Step 2: Create a frequency counter for both arrays
  const frequencyCounter1: { [key: string | number]: number } = {};
  const frequencyCounter2: { [key: string | number]: number } = {};

  // Step 3: Count frequencies for arr1
  for (const value of arr1) {
    frequencyCounter1[value] = (frequencyCounter1[value] || 0) + 1;
  }

  // Step 4: Count frequencies for arr2
  for (const value of arr2) {
    frequencyCounter2[value] = (frequencyCounter2[value] || 0) + 1;
  }

  // Step 5: Compare the frequency counters
  for (const key in frequencyCounter1) {
    if (frequencyCounter1[key] !== frequencyCounter2[key]) {
      return false;
    }
  }

  return true;
};

export const groupBy = <T>(
  array: T[],
  callback: (item: T) => string
): Record<string, T[]> =>
  array.reduce<Record<string, T[]>>((accu, item) => {
    const key = callback(item);

    if (!accu[key]) {
      return {
        ...accu,
        [key]: [item],
      };
    }

    return {
      ...accu,
      [key]: [...accu[key], item],
    };
  }, {});

export const makePageTitle = (title?: string) =>
  title ? `${title} | Rock Rabbit` : "Rock Rabbit";

export const uniqBy = <T, K>(array: T[], keyFn: (item: T) => K): T[] => {
  const seen = new Set<K>();
  return array.filter((item) => {
    const key = keyFn(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

export const transformArrayToHash = <T extends string | number>(
  arr: T[]
): Record<string, T> =>
  arr.reduce(
    (a, item) => ({
      ...a,
      [item]: true,
    }),
    {}
  );

export const transformArrayToHashBy = <
  I extends string | number,
  T extends { [key: string | number]: any },
  R = T,
>(
  arr: T[],
  keyFn: (item: T) => I,
  mapFn?: (item: T) => R
): Record<I, R> => {
  return arr.reduce(
    (a, item) => ({
      ...a,
      [keyFn(item)]: mapFn ? mapFn(item) : item,
    }),
    {}
  ) as unknown as Record<I, R>;
};

export const transformArrayToHashById = <
  T extends { id: string; [key: string]: any },
  R = T,
>(
  arr: T[],
  mapFn?: (item: T) => R
): Record<string, R> => transformArrayToHashBy(arr, (item) => item.id, mapFn);

export const findKeysWith = <T>(
  map: Record<string, T>,
  predicate: (value: T) => boolean
): string[] => {
  return Object.entries(map).reduce<string[]>((a, [key, value]) => {
    if (predicate(value)) {
      return [...a, key];
    }

    return a;
  }, []);
};

export const findKeyWith = <T>(
  map: Record<string, T>,
  predicate: (value: T) => boolean
): string | null => {
  return findKeysWith(map, predicate)[0] ?? null;
};

export const isNumberString = (value: string): boolean => {
  return /^-?\d*\.?\d+$/.test(value);
};

export const isServer = () => typeof window === "undefined";

export const isProd = () =>
  process.env.NEXT_PUBLIC_ENV === Environments.PRODUCTION;

export const isDev = () =>
  process.env.NEXT_PUBLIC_ENV === Environments.DEVELOPMENT;

export const isNil = (value: unknown): value is null | undefined =>
  value === null || value === undefined;

export const isNull = (value: unknown): value is null => value === null;

export const isUndefined = (value: unknown): value is undefined =>
  value === undefined;

export const isFunction = (value: unknown): value is (...args: any[]) => any =>
  typeof value === "function";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export const getFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null
): string =>
  (firstName ?? "") + (!!firstName && !!lastName ? " " : "") + (lastName ?? "");

export const getValue = <T>(obj: T, path: string): unknown => {
  return path.split(".").reduce((acc, key) => {
    if (isNil(acc)) {
      return undefined;
    }

    return !isNaN(Number(key)) ? acc[Number(key)] : acc[key];
  }, obj as any);
};

export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  const result: Partial<T> = {};

  for (const key in obj) {
    if (Object.hasOwn(obj, key) && !keys.includes(key as unknown as K)) {
      result[key] = obj[key];
    }
  }

  return result as Omit<T, K>;
};

export const isInView = (domElement: HTMLElement) => {
  const rect = domElement.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const ifTrueAsIsOr = <T, R extends null | undefined>(
  fn: (param: T) => boolean,
  param: T,
  or: R
): T | R => {
  if (fn(param)) {
    return param;
  }

  return or;
};

export const ifTrueAsIsOrNull = <T>(
  fn: (param: T) => boolean,
  param: T
): T | null => ifTrueAsIsOr(fn, param, null);

export const ifTrueAsIsOrUndefined = <T>(
  fn: (param: T) => boolean,
  param: T
): T | undefined => ifTrueAsIsOr(fn, param, undefined);

export const getUrlByUserStatus = (
  userStatus: AppApiApiV1SchemasUserUserStatus
): string | null => {
  switch (userStatus) {
    case AppApiApiV1SchemasUserUserStatus.Active: {
      return null;
    }

    case AppApiApiV1SchemasUserUserStatus.PendingTermsAcceptance: {
      return URLS.auth.termsAndConditions();
    }

    case AppApiApiV1SchemasUserUserStatus.PendingContractorOrgApproval: {
      return URLS.auth.pendingApproval();
    }

    case AppApiApiV1SchemasUserUserStatus.PendingContractorOrgSelection:
    case AppApiApiV1SchemasUserUserStatus.PendingSignup:
    case AppApiApiV1SchemasUserUserStatus.PendingUserTypeSelection: {
      return URLS.auth.onboarding();
    }

    case AppApiApiV1SchemasUserUserStatus.Suspended: {
      return URLS.auth.signOut();
    }
  }
};

export const openPdfPreview = (url: string) => {
  window.open(URLS.pdfViewer(url), "_blank", "noopener,noreferrer");
};

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");

  link.href = url;
  link.download = filename;
  link.target = "_blank";
  link.click();
};
