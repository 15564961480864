import { Environments } from "@/types/environments.types";

// OK to store on client side: https://docs.launchdarkly.com/sdk/concepts/client-side-server-side#client-side-id
export const LD_CLIENT_IDS: { [key in Environments]: string } = {
  [Environments.STAGING]: "67d482b0cbd398096097b3aa",
  [Environments.DEVELOPMENT]: "67d482cf8ae43609ace57a1d",
  [Environments.TEST]: "67d482b0cbd398096097b3aa",
  [Environments.PRODUCTION]: "66a2963423ccec101de39fe7",
  [Environments.DEMO]: "67d482c5466c5d09a59c40c2",
};

// Fallback to production flags, in case something breaks.
// We don't want to use lower environment flags on prod.
export const LD_CLIENT_ID =
  LD_CLIENT_IDS[process.env.NEXT_PUBLIC_ENV] ??
  LD_CLIENT_IDS[Environments.PRODUCTION];

export enum FEATURE_FLAGS {
  SAMPLE_FEATURE = "sample-feature",
  REACT_EXAMPLE_FLAG = "react-example-flag",
  USER_EMAIL_AUTH = "user-email-auth",
}
