import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";

import { AppEnum } from "@/constants";
import {
  APP_LOGO,
  DEFAULT_BRAND_CONFIGURATION,
} from "@/contexts/branding/constants";
import { BrandConfiguration } from "@/contexts/branding/types";
import { useUserInfoQuery } from "@/hooks/queries/useAuthQueries";

import {
  isAOSmithDefault,
  isAOSmithLowes,
  isAOSmithStateWaterHeaters,
  isCPAU,
  isSVCE,
} from "./utils";

export const useDetermineApp = () => {
  const { data: user } = useUserInfoQuery();
  const pathname = usePathname();

  /**
   * 1. Determine the branding of the app by checking the hostname or pathname
   * 2. If the user is verified white label app, show that branding
   * 3. If the user is not verified_xxx, show the default branding
   */
  const branding = (() => {
    // 1.
    if (isAOSmithDefault({ pathname })) {
      return AppEnum.AOSmithDefault;
    }
    if (isAOSmithStateWaterHeaters({ pathname })) {
      return AppEnum.AOSmithStateWaterHeaters;
    }
    if (isAOSmithLowes({ pathname })) {
      return AppEnum.AOSmithLowes;
    }
    if (isSVCE({ hostname: location.hostname })) {
      return AppEnum.SVCE;
    }
    if (isCPAU({ hostname: location.hostname })) {
      return AppEnum.CPAU;
    }

    // 2.
    // there is very rare case that user are verified in multiple white label apps
    // in that case, we will show the verified app based on the priority
    if (user?.verified_svce) {
      return AppEnum.SVCE;
    }
    if (user?.verified_cpau) {
      return AppEnum.CPAU;
    }

    // 3.
    return AppEnum.RockRabbit;
  })();

  return {
    branding,
  };
};

export const useBrandingConfig = (app: AppEnum) => {
  const { t } = useTranslation();

  const BRAND_CONFIGURATIONS: { [key in AppEnum]?: BrandConfiguration } = {
    [AppEnum.SVCE]: {
      brandName: "svce",
      shouldRedirectContractorsToRockRabbit: true,
      contractorListFilter: "svce_eligible_only",
      contractorDirectoryDisclaimer: t("quotable_job_pre_select.disclaimer"),
      translationPrefix: "svce",
      homeContactInfo: {
        title: t("dashboard.contact_our_advisor.header"),
        phoneNumber: t("dashboard.contact_our_advisor.phone.value"),
        email: t("dashboard.contact_our_advisor.email.value"),
        availableHours: t("dashboard.contact_our_advisor.hours.value"),
        liveChatLink: "https://goelectric.svcleanenergy.org/#start",
      },
      knowledgeCenter: {
        ehubInfo: {
          label: t("knowledge_center.visit_svce_message.title"),
          icon: {
            url: "https://svcleanenergy.org/ehub/",
            label: t("knowledge_center.visit_svce_message_icon_label"),
          },
        },
      },
    },
    [AppEnum.CPAU]: {
      brandName: "cpau",
      shouldRedirectContractorsToRockRabbit: true,
      contractorListFilter: "cpau_eligible_only",
      contractorDirectoryDisclaimer: t(
        "quotable_job_pre_select.CPAU.directory_disclaimer"
      ),
      translationPrefix: "cpau",
      homeContactInfo: {
        title: t("dashboard.contact.cpau.header"),
        phoneNumber: "650-713-3411",
        email: "switch@cityofpaloalto.org",
      },
      knowledgeCenter: {
        ehubInfo: {
          label: t("knowledge_center.visit_cpau_message"),
          icon: {
            url: "https://www.cityofpaloalto.org/goelectric",
            label: t("knowledge_center.visit_cpau_message_icon_label"),
          },
        },
      },
    },
  };

  return {
    brandConfig: BRAND_CONFIGURATIONS[app] ?? DEFAULT_BRAND_CONFIGURATION,
    logo: APP_LOGO[app] ?? null,
  };
};
