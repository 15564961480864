import Link from "next/link";
import { Button as PrimeButton } from "primereact/button";
import {
  ButtonHTMLAttributes,
  HTMLAttributeAnchorTarget,
  cloneElement,
} from "react";

import { cx } from "@/utils";

type CustomButtonProps = {
  intercomId?: string;
  testId?: string;
  linkTarget?: HTMLAttributeAnchorTarget;
  linkUrl?: string;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  squash?: boolean;
  flat?: boolean;
  variant?: "text" | "outlined" | "contained" | "link";
  color?: "primary" | "success" | "info" | "gray" | "warn" | "danger";
  size?: "default" | "small";
  iconPosition?: "left" | "right";
  label: string;
  icon?: JSX.Element;
  className?: string;
  /**
   *
   * It only works when linkUrl is provided
   */
  buttonClassName?: string;
  labelClassName?: string;
};

type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  keyof CustomButtonProps
> &
  CustomButtonProps;

export const Button = ({
  intercomId,
  testId,
  linkTarget,
  linkUrl,
  loading,
  disabled,
  readonly,
  squash,
  flat,
  variant = "contained",
  color = "primary",
  size = "default",
  iconPosition = "left",
  label,
  icon,
  className,
  buttonClassName,
  labelClassName,
  ...props
}: ButtonProps) => {
  /**
   * NOTE:
   *
   * Current bug in PrimeReact Button component:
   * icon and loading prop cannot be used together
   * we will handle it later
   */
  const button = (
    <PrimeButton
      role={linkUrl ? "link" : undefined}
      data-intercom-target={intercomId}
      loading={loading}
      disabled={disabled || readonly}
      link={variant === "link"}
      text={variant === "text"}
      outlined={variant === "outlined"}
      label={label}
      aria-label={label}
      size={size !== "default" ? size : undefined}
      icon={
        icon
          ? cloneElement(icon, {
              className: cx(
                "p-button-icon",
                {
                  "p-button-icon-left": iconPosition === "left",
                  "p-button-icon-right": iconPosition === "right",
                },
                icon.props.className
              ),
            })
          : null
      }
      className={cx(
        "min-w-24",
        {
          "p-button-contained": variant === "contained",
          "p-button-primary": color === "primary",
          "p-button-danger": color === "danger",
          "p-button-warn": color === "warn",
          "p-button-gray": color === "gray",
          "p-button-success": color === "success",
          "p-button-info": color === "info",
          "p-button-flat": flat,
          "p-button-squash": squash,
          "p-button-readonly": readonly && !disabled,
        },
        linkUrl ? buttonClassName : className
      )}
      pt={{
        root: {
          "data-testid": testId,
        },
        label: {
          className: labelClassName,
        },
      }}
      {...props}
    />
  );

  if (linkUrl) {
    return (
      <Link
        className={cx("block w-fit", className)}
        target={linkTarget}
        href={linkUrl}
        tabIndex={-1}>
        {button}
      </Link>
    );
  }

  return button;
};
