import { UseMutateFunction, UseQueryResult } from "@tanstack/react-query";

import { LOCAL_STORAGE_KEYS } from "@/constants/storageKeys";
import useAuthStore from "@/store/auth";

import { BackendAPI, HttpResponse, RequestParams } from "./BackendAPI";

export const client = new BackendAPI({
  baseUrl: process.env.NEXT_PUBLIC_API_BASE,
  securityWorker: () => {
    const languageSelected = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALE);
    const { token } = useAuthStore.getState();

    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(languageSelected ? { "Accept-Language": languageSelected } : {}),
        },
      } as RequestParams;
    }

    if (languageSelected) {
      return {
        headers: {
          "Accept-Language": languageSelected,
        },
      } as RequestParams;
    }
  },
});

export type Client = BackendAPI<Record<string, never>>;
export type API = Client["api"];

// Utility type to extract the error type from UseMutateFunction
type ExtractResponseType<T> =
  T extends UseMutateFunction<infer D, infer E, infer _R, infer _>
    ? HttpResponse<D, E>
    : T extends UseQueryResult<infer D, infer E>
      ? HttpResponse<D, E>
      : never;

// react-query mutations return a generic Error in `onError`.
// however, in many instances, you can get the actual backend API error, with
// correct types. use like so:
//
//    onError: (error) => {
//      if (isHttpResponse<typeof mutate>(error)) {
//        // here, you can access error.error.message/.details/.locize_key etc....
//
export function isHttpResponse<T>(obj: unknown): obj is ExtractResponseType<T> {
  return !!obj && typeof obj === "object" && "data" in obj && "error" in obj;
}
